<template>
  <div v-if="model">
    <h3 class="model-type-title">{{ model.name }}</h3>
    <p>{{ model.info }}</p>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    model: {
      type: Object,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.model-details {
  width: 40%;
}
.model-type-title {
  text-align: left;
  width: 100%;
  border-bottom: 1px solid var(--grey-primary-color);
  padding: 0.5rem 0;
  margin-bottom: 0.75rem;
}
p {
  width: 100%;
  text-align: left;
}
</style>
