<template>
  <footer>
    <span class="text"> Paintpal V.0.2 </span>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.text {
  font-size: 0.9rem;
  color: var(--text-secondary-color);
}
</style>
