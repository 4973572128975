<template>
  <div class="main">
    <header>
      <Navbar @toggle-dialog="handleToggleDialog" />
    </header>
    <section class="generator-container">
      <Generator @toggle-dialog="handleToggleDialog" />
    </section>

    <Footer />
  </div>

  <Dialog :open="dialogOpen" @toggle-dialog="handleToggleDialog" />
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import Generator from "./components/Generator/index.vue";
import Dialog from "./components/Dialog.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Generator,
    Dialog,
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  methods: {
    handleToggleDialog(status) {
      this.dialogOpen = status;
    },
  },
};
</script>

<style>
*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  --text-primary-color: #fff;
  --text-secondary-color: #e0dddd;
  --dark-primary-color: #202020;
  --grey-primary-color: #3b3b3b;
}

body {
  box-sizing: border-box;
  margin: 0;
  background: #202020;
  overflow-x: hidden;
}

.main {
  width: 100%;
  max-width: 80rem;
  padding: 1rem;
}

.generator-container {
  margin: 3rem 0;
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
#app {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 570px) {
  body {
    font-size: 85%;
  }
  .generator-container {
    margin: 2rem 0;
  }
}
</style>
