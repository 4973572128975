import axios from "../utils/axios";

export async function generateText({ endpoint, options, ...values }) {
  try {
    const response = await axios.post(endpoint, {
      ...options,
      ...values,
    });
    return response.data;
  } catch (err) {
    throw Error(err);
  }
}
