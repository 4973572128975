<template>
  <div class="container" v-if="isDataLoaded">
    <div class="form-container">
      <h1>Paintpal</h1>
      <form @submit.prevent="handleSubmit">
        <div class="model-selector-group">
          <div class="model-selector">
            <label for="type" class="label">
              Type
              <select
                id="type"
                v-model="selectedType"
                @change="handleInitializedModel()"
              >
                <option v-for="item in typeValues" :key="item" :value="item">
                  {{ item }}
                </option>
              </select>
            </label>
          </div>
          <div class="model-selector">
            <label for="model" class="label">
              Model
              <select
                id="model"
                v-model="selectedModel"
                @change="handleInitializeValues()"
              >
                <option
                  v-for="model in models"
                  :key="model.name"
                  :value="model"
                >
                  {{ model.name }}
                </option>
              </select>
            </label>
          </div>
        </div>

        <fieldset class="input-group">
          <label
            v-for="inputText in selectedModel.inputParams"
            :key="inputText.id"
            class="prompt-container"
          >
            {{ inputText.name }}
            <textarea
              spellcheck
              v-model="values[`${inputText?.name}`]"
              type="text"
              :placeholder="inputText.default"
              :name="inputText.name"
            />
            <div class="helper-text-container">
              <small class="helper-text">
                {{ values[`${inputText?.name}`]?.length || 0 }} Characters
              </small>
            </div>
          </label>
        </fieldset>

        <fieldset class="slider-container">
          <label v-for="(val, key, index) in optionValues" :key="index">
            <legend>
              {{ key }}
            </legend>
            <Slider
              :step="options[key].type === 'int' ? 1 : -1 || 1"
              :min="options[key].min || 0"
              :max="options[key].max || 10"
              v-model="optionValues[key]"
              showTooltip="focus"
            />
          </label>
        </fieldset>

        <fieldset v-if="selectedModel.password" class="slider-container">
          <label>
            <legend>Password</legend>
            <input
              v-model="values[`password`]"
              placeholder="Password for this model"
              name="password"
              type="password"
              class="password"
            />
          </label>
        </fieldset>

        <div class="button-container">
          <button
            :disabled="
              loading ||
              this.isTextboxEmpty ||
              (selectedModel?.password &&
                selectedModel?.password !== values.password)
            "
          >
            <div class="button-loading-container" v-if="loading">
              <LoadingDots />
            </div>
            {{ loading ? "" : "generate" }}
          </button>
        </div>
      </form>

      <div v-if="result.length && !loading" class="result">
        <h3>Output:</h3>
        <img v-if="returnType === 'image'" :src="result" class="result-image" />
        <p v-else>{{ result }}</p>
      </div>
      <small
        class="result result--empty"
        v-if="!loading && result.length === 0"
      >
        "Please input some text in the text box for the model to generate
        content!"
      </small>
      <div class="result-loading-container">
        <LoadingPulse v-if="loading" />
      </div>
    </div>
    <div class="model-info-container">
      <ModelInfo :model="selectedModel" />

      <div class="file-upload-container">
        <label>
          <legend>Upload .zip file with all images in same category</legend>
          <input type="file" @change="handleFileSelect" accept=".zip"/>
        </label>
        <button @click.prevent="uploadAndStartTraining" :disabled="!selectedFile">Upload And Start Training</button>
      </div>

      <div class="status-container">  
        <label for="status-field">Current Status:</label>  
        <input id="status-field" type="text" :value="status" readonly />  
      </div>

      <div class="training-models-container">
        <h4>Models currently training:</h4>
        <ul>
          <li v-for="(model, index) in trainingConfig" :key="index">{{ model }}</li>
        </ul>
      </div>


    </div>

  </div>
  <div v-else>Loading...</div>
</template>

<script>
import { generateText } from "../../services/ModelService";
import ModelInfo from "../ModelInfo.vue";
import LoadingDots from "../LoadingDots.vue";
import { useToast } from "vue-toastification";
import LoadingPulse from "../LoadingPulse.vue";
import Slider from "@vueform/slider";
import { getModelsConfig } from '../../utils/models';

export default {
  name: "Generator",
  components: { ModelInfo, LoadingDots, LoadingPulse, Slider },

  data() {
    return {
      values: {},
      selectedModel: null,
      selectedType: null,
      loading: false,
      result: "",
      returnType: "text",
      selectedFile: null,
      isDataLoaded: false, // Add this new property
      status: "Ready",
    };
  },  

  async created() {
    try {
      const modelsConfig = await getModelsConfig();
      this.nowConfig = modelsConfig?.now_configs;
      this.trainingConfig = modelsConfig?.training_configs;
      this.selectedModel = this.nowConfig[0];
      this.selectedType = this.nowConfig[0].type;
      this.handleInitializeValues();
      this.isDataLoaded = true; // Set this to true after fetching modelsConfig
      // If trainingConfig is not empty list, then status is "Training"
      if (this.trainingConfig && this.trainingConfig > 0) {
        this.status = "Training";
      }
    } catch (error) {
      console.error(error);
    }
  }, 

  computed: {
    isTextboxEmpty() {
      for (let key in this.values) {
        if (typeof this.values[key] !== String) return false;
        else if (this.values[key].replace(" ", "").length === 0) return true;
      }
      return false;
    },

    options() {
      return this.selectedModel.options;
    },

    optionValues() {
      var values = {};
      for (let key in this.selectedModel.options) {
        values[key] = this.selectedModel.options[key].default || 1;
      }
      return values;
    },

    typeValues() {
      return new Set(this.nowConfig.map((model) => model.type));
    },

    models() {
      let matchingModels = [];
      this.nowConfig.forEach((model) => {
        if (model.type === this.selectedType) matchingModels.push(model);
      });
      return matchingModels;
    },

    passwordCorrect() {
      console.log(this.selectedModel, this.values);
      if (!this.selectedModel.password) return true;
      else if (this.selectedModel.password === this.values.password)
        return true;
      else return false;
    },
  },

  methods: {
    handleOpenDialog() {
      this.$emit("toggle-dialog", true);
    },

    handleInitializedModel() {
      this.selectedModel = this.models[0];
      this.handleInitializeValues();
    },

    handleInitializeValues() {
      this.values = {};

      this.selectedModel.inputParams.forEach((item) => {
        this.values[`${item.name}`] = item.default;
      });
    },

    async handleSubmit() {
      this.loading = true;
      try {
        const { result, returnType } = await generateText({
          ...this.values,
          endpoint: this.selectedModel.endpoint,
          options: this.optionValues,
        });
        if (returnType) this.returnType = returnType;
        else this.returnType = "text";
        this.result = result[0];
      } catch (err) {
        const toast = useToast();
        this.result = "";
        toast.error("Server Call Failed");
      }
      this.loading = false;
    },

    handleFileSelect(event) {
      this.selectedFile = event.target.files[0];
    },

    // async uploadFile() {
    //   console.log("Start Uploading file");
    //   if (!this.selectedFile) {
    //     return;
    //   }

    //   const formData = new FormData();
    //   formData.append("file", this.selectedFile);

    //   try {
    //     const response = await fetch("https://aimodel-train.dev-metaverse.fun/data/upload", {
    //       method: "POST",
    //       body: formData,
    //     });

    //     if (response.ok) {
    //       const result = await response.json();
    //       console.log("File uploaded successfully:", result);
    //     } else {
    //       console.error("File upload failed:", response.statusText);
    //     }
    //   } catch (error) {
    //     console.error("File upload error:", error);
    //   }
    // },

    getFormattedTimestamp() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    },

    async uploadFile(file) {
      this.status = 'Uploading';

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch("https://aimodel-train.dev-metaverse.fun/data/upload", {
          method: "POST",
          body: formData
        });

        if (!response.ok) {
          throw new Error("File upload failed");
        }

        const result = await response.json();
        this.status = 'Uploaded';
        console.log("File uploaded successfully", result);
      } catch (error) {
        this.status = 'Upload failed';
        console.error("Error uploading file:", error);
      }
    },

    async createTaskWithDelay(fileName, delay = 20000) {
      this.status = "Labeling...";
      setTimeout(async () => {
        const modelName = `${fileName.split(".")[0]}`;
        const createTaskResponse = await fetch("https://aimodel-train.dev-metaverse.fun/task/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model_name: modelName,
            rewrite: false,
          }),
        });

        if (createTaskResponse.ok) {
          this.status = 'Start Training...Please refresh and check again';
          console.log("Task created successfully");
        } else {
          this.status = 'Start Training Failed...';
          console.error("Task creation failed:", createTaskResponse.statusText);
        }
      }, delay);
    },
 
    async createTask(fileName) {
      try {
        const modelName = `${fileName.split(".")[0]}`;
        const response = await fetch("https://aimodel-train.dev-metaverse.fun/task/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model_name: modelName,
            rewrite: false,
          }),
        });

        if (!response.ok) {
          throw new Error("Task creation failed");
        }

        const result = await response.json();
        console.log("Task created successfully", result);
        alert("Training task created, name: ", modelName);
      } catch (error) {
        console.error("Error creating task:", error);
      }
    },

    async uploadAndStartTraining() {
      if (this.selectedFile) {
        const timestamp = this.getFormattedTimestamp();
        const newFileName = `${this.selectedFile.name.split(".")[0]}-${timestamp}.zip`;

        // Create a new File object with the updated name
        const renamedFile = new File([this.selectedFile], newFileName, {
          type: this.selectedFile.type,
        });

        await this.uploadFile(renamedFile);
        await this.createTaskWithDelay(newFileName);
      }
    },
  },
};
</script>

<style scoped>
@import "./index.css";
</style>

<style src="@vueform/slider/themes/default.css"></style>
