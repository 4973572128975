<template>
  <div class="container" v-if="open">
    <div @click="handleCloseDialog" class="backdrop"></div>
    <div class="dialog-container">
      <div class="dialog">
        <img
          role="button"
          aria-label="close"
          @click="handleCloseDialog"
          class="cross-icon"
          alt="cancel"
          src="../assets/cancel.png"
        />
        <div
          v-for="model in nowConfig"
          :key="model.name"
          style="margin: 2rem auto"
        >
          <ModelInfo :model="model" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getModelsConfig } from '../utils/models';
import ModelInfo from "./ModelInfo.vue";

export default {
  name: "Dialog",
  data() {
    return {
      nowConfig: []
    };
  },
  async created() {
    const modelsConfig = await getModelsConfig();
    this.nowConfig = modelsConfig?.now_configs;
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    handleCloseDialog() {
      this.$emit("toggle-dialog", false);
    },
  },
  components: { ModelInfo },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: var(--dark-primary-color);
  opacity: 0.75;
  cursor: pointer;
}
.dialog-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog {
  overflow: auto;
  position: relative;
  max-width: 100%;
  min-width: 30rem;
  height: 60%;
  max-height: 90vh;
  padding: 2rem;
  background-color: var(--dark-primary-color);
  border: 1px solid var(--text-secondary-color);
  border-radius: 0.5rem;
}

.cross-icon {
  position: fixed;
  right: 2rem;
  top: 1rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 570px) {
  .dialog {
    width: 90%;
    min-width: initial;
    padding: 0.75rem;
  }
  .dialog-container {
    width: 90%;
  }
}
</style>
