import axiosInstance from "./axios";

export async function getModelsConfig() {
  const response = await axiosInstance.get('https://aimodel-train.dev-metaverse.fun/configs/get')
  return response.data;
  // return new Promise((resolve, reject) => {
  //   fetch('https://aimodel-train.dev-metaverse.fun/configs/get')
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then(data => {
  //       resolve(data);
  //     })
  //     .catch(error => {
  //       reject(error);
  //     });
  // });
}

// const modelsConfigPromise = getModelsConfig()
//   .catch(error => {
//     console.error(error);
//   });

// export default modelsConfigPromise;
